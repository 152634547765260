import React, { useState, useEffect, useRef } from 'react';
import './Contact.css';
// import React, { useEffect, useRef } from 'react';
import image1 from '../../assets/contact/contactcover.jpeg';

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`Submitted:\nName: ${name}\nEmail: ${email}\nContact No: ${contactNo}\nMessage: ${message}`);
  };

  const handleReset = () => {
    setName('');
    setEmail('');
    setContactNo('');
    setMessage('');
  };

  const messagesRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    messagesRef.current.forEach(message => {
      observer.observe(message);
    });

    return () => {
      messagesRef.current.forEach(message => {
        observer.unobserve(message);
      });
    };
  }, []);

  const sectionRef = useRef(null);

  useEffect(() => {
    const animateValue = (id, start, end, duration, appendTon) => {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        const value = Math.floor(progress * (end - start) + start);
        document.getElementById(id).innerText = appendTon ? value + "+ ton" : value + "+";
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    };

    const handleIntersection = (entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        animateValue("jobsCreated", 0, 100, 2000, false);
        animateValue("plasticRecovered", 0, 28870, 2000, true); // Append " ton" for this animation
        animateValue("womenSupported", 0, 2600, 2000, true); // Append " ton" for this animation
        animateValue("villagesOperational", 0, 8, 2000, false);
        observer.disconnect(); // Disconnect observer after animation starts
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
    });

    observer.observe(sectionRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);


  return (
    <div className='contact-section'>
      {/* <img src={image1} className="image" alt="Envigrow" /> */}
      <div className="mt-4 mb-4 text-center" style={{ letterSpacing: "2px", fontSize:"1.5rem" }}>
          "Join the Revolution in Sustainable Waste Management while creating <br />dignified employment with Envigrow"
      </div>
      <div className="impact-section" ref={sectionRef}>
      
        <h2>Our Impacts So Far</h2>
        <div className="impact-item">
          <span id="jobsCreated">0</span>
          <p>Dignified Employment Created</p>
        </div>
      {/* <div className='impact-item-back'> */}
        <div className="impact-item">
          <span id="plasticRecovered">0</span>
          <p>Total Amount of Waste Diverted from Landfill</p>
        </div>
        <div className="impact-item">
          <span id="womenSupported">0</span>
          <p>Total Amount of Waste Recycled</p>
        </div>
        <div className="impact-item">
          <span id="villagesOperational">0</span>
          <p>Operational Towns</p>
        </div>
      {/* </div> */}
    </div>

      <div className="chat-container">
        <div className="message gray" ref={el => messagesRef.current[0] = el}>
          <span>Why should you Join us ? </span>
        </div>
        <div className="message blue" ref={el => messagesRef.current[1] = el}>
          <span>
            Purpose: Contribute to the sustainability by embracing a shared
            purpose and maximizing your impact towards this common goal
          </span>
        </div>
        <div className="message gray" ref={el => messagesRef.current[2] = el}>
          <span>Contribution to the Environment: Participate in encouraging 
            giving back to the society initiatives and showcase your proactive approach.</span>
        </div>
        <div className="message blue" ref={el => messagesRef.current[3] = el}>
          <span>Opportunity to work at the grassroot level: Experience the chance to 
            make a tangible difference by working directly at the grassroots level.</span>
        </div>
        <div className="message gray" ref={el => messagesRef.current[4] = el}>
          <span>Professional Development: Begin channelling your efforts towards 
            creating a healthier ecosystem, enhancing surroundings, and improving the lives of people</span>
        </div>
        <div className="message blue" ref={el => messagesRef.current[5] = el}>
          <span>Stay Responsible: Fulfill your ethical responsibilities by minimizing 
            waste and transforming it into valuable resources, thus driving the circular 
            economy forward.</span>
        </div>
        
        <div className="message gray" ref={el => messagesRef.current[6] = el}>
          <span>Enquire now</span>
        </div>
      </div>
      <div className="py-3 px-3 px-md-5">
        {/* <div className="mt-4 mb-4 text-center" style={{ letterSpacing: "2px" }}>
          "Join the Revolution in Sustainable Waste Management while creating <br />dignified employment with Envigrow"
        </div> */}
        <div className="row">
          <div className="mt-4 col-12 col-md-6">
            <h4>EnviGrow Sustainable Solutions LLP</h4>
            <address>
              <div>44, Adarsh Nagar, Roorkee</div>
              Uttarakhand- 247667<br />
              <abbr title="Telephone">Tel: </abbr>
              <a href="tel:+919759127134" style={{ color: 'black' }}>+91-63957 58734, +91-88509 97079</a><br />
              <abbr title="Mail">Mail: </abbr>
              <a href="mailto:envigrowsustainables@gmail.com" style={{ color: 'black' }}>info@envigrowss.com</a>
            </address>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 mb-3">
            <h4>Send us a message</h4>
            <form>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="form-group mb-3">
                    <label htmlFor="name" style={{ color: 'white' }}>Name</label>
                    <input
                      type="text"
                      id="name"
                      className="form-control"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="form-group mb-3">
                    <label htmlFor="email" style={{ color: 'white' }}>Email</label>
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      placeholder="example@email.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="form-group mb-3">
                    <label htmlFor="telephone" style={{ color: 'white' }}>Telephone</label>
                    <input
                      type="tel"
                      id="telephone"
                      className="form-control"
                      placeholder="9876543210"
                      value={contactNo}
                      onChange={(e) => setContactNo(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="textarea" style={{ color: 'white' }}>Message:</label>
                <textarea
                  id="textarea"
                  className="form-control"
                  rows="5"
                  placeholder="Write your message here.."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary btn-sm me-2" style={{ backgroundColor: '#0c90a4' }} onClick={handleSubmit}>Submit</button>
              <button type="reset" className="btn btn-secondary btn-sm" onClick={handleReset}>Reset</button>
            </form>
          </div>
          <div className="col-12 col-lg-6">
            <h4>Where to find us:</h4>
            <div className="embed embed-responsive embed-responsive-4by3">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13838.471299094046!2d77.89073283808195!3d29.875293700142382!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390eb361c0000001%3A0x2f42e4643246b47b!2sEnviGrow%20Sustainable%20Solution!5e0!3m2!1sen!2sin!4v1715720269089!5m2!1sen!2sin"
                width="100%"
                height="350"
                style={{ border: '0' }}
                allowFullScreen
                title="BIET Location"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="chat-container">
        <div className="message gray" ref={el => messagesRef.current[7] = el}>
          <span>Send us a message</span>
        </div>
        <div className="message blue" ref={el => messagesRef.current[8] = el}>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
          />
        </div>
        <div className="message blue" ref={el => messagesRef.current[9] = el}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your email ID"
          />
        </div>
        <div className="message blue" ref={el => messagesRef.current[10] = el}>
          <input
            type="text"
            value={contactNo}
            onChange={(e) => setContactNo(e.target.value)}
            placeholder="Contact No."
          />
        </div>
        <div className="message blue" ref={el => messagesRef.current[11] = el}>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Your message"
            rows="3"
          />
        </div>
        <div className="button-container" ref={el => messagesRef.current[12] = el}>
          <button className="btn btn-primary btn-sm" onClick={handleSubmit} style={{ backgroundColor: '#0c90a4' }}>Submit</button>
          <button className="btn btn-secondary btn-sm" onClick={handleReset}>Reset</button>
        </div>
      </div> */}

      <div className="our-work-section wave-background-work"></div>
    </div>
  );
}

export default Contact;
