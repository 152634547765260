import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faGoogle, faInstagram, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="footer" >
      <div className="container">
        <section className="links-section">
          <div className="row text-center d-flex justify-content-center pt-5">
            <div className="col-md-2">
              <h6 className="text-uppercase font-weight-bold">
                <a href="/" className="text-white">HOME</a>
              </h6>
            </div>
            <div className="col-md-2">
              <h6 className="text-uppercase font-weight-bold">
                <a href="/about-us" className="text-white">WHO WE ARE</a>
              </h6>
            </div>
            {/* <div className="col-md-2">
              <h6 className="text-uppercase font-weight-bold">
                <a href="/what-we-do" className="text-white">WHAT WE DO</a>
              </h6>
            </div> */}
            <div className="col-md-2">
              <h6 className="text-uppercase font-weight-bold">
                <a href="/HOW-WE-DO" className="text-white">HOW WE DO</a>
              </h6>
            </div>
            <div className="col-md-2">
              <h6 className="text-uppercase font-weight-bold">
                <a href="/MEDIA" className="text-white">MEDIA</a>
              </h6>
            </div>
            <div className="col-md-2">
              <h6 className="text-uppercase font-weight-bold">
                <a href="/career" className="text-white">CAREERS</a>
              </h6>
            </div>
          </div>
        </section>
        <hr style={{color:'white'}}/>
        <section className="text-section mb-2">
          <h1 className="row d-flex justify-content-center" style={{color:'white'}}>EnviGrow Sustainable Solutions</h1>
          <div className="row d-flex justify-content-center" style={{letterSpacing:'2px'}}>
            <div className="col-lg-8">
              <p>
              A Step towards Rural and Semi Urban Sustainable Solid Waste Management
              </p>
            </div>
          </div>
        </section>
        <section className="social-section text-center mb-5">
          <a href="#!" className="text-white me-4">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a href="#!" className="text-white me-4">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          
          <a href="#!" className="text-white me-4">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="#!" className="text-white me-4">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          
        </section>
      </div>
      <div className="footer-bottom text-center p-3">
        © 2021 EnviGrow Sustainable Solutions LLP All rights reserved 
        <div>
        <a className="text-white" href="/Terms">Terms & Conditions and Cookie Policy </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
