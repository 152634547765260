import React from "react";
import './Howwedo.css';
import Main from '../../assets/vectors/vec.svg';

// Importing images
import ice1 from '../../assets/How_we_do/ICE/image1.jpeg';
import ice2 from '../../assets/How_we_do/ICE/image2.jpeg';
import ice3 from '../../assets/How_we_do/ICE/image3.jpeg';

import d2d1 from '../../assets/How_we_do/D2D/image1.jpeg';
import d2d2 from '../../assets/How_we_do/D2D/image2.jpeg';
import d2d3 from '../../assets/How_we_do/D2D/image3.jpeg';

import mrf1 from '../../assets/How_we_do/MRF/image1.jpeg';
import mrf2 from '../../assets/How_we_do/MRF/image2.jpeg';
import mrf3 from '../../assets/How_we_do/MRF/image3.jpeg';
import mrf4 from '../../assets/How_we_do/MRF/image4.jpeg';
import mrf5 from '../../assets/How_we_do/MRF/image5.jpeg';

import pcf1 from '../../assets/How_we_do/PCF/image1.jpeg';
import pcf2 from '../../assets/How_we_do/PCF/image2.jpeg';
import pcf3 from '../../assets/How_we_do/PCF/image3.jpeg';
import pcf4 from '../../assets/How_we_do/PCF/image4.jpeg';

import app1 from '../../assets/How_we_do/APP/image1.jpeg';

import gis1 from '../../assets/How_we_do/GIS/image1.jpeg';

import Carousel from 'react-bootstrap/Carousel';

const Howwedo = () => {
    return (
        <div className="How-section">
            <div className="digitalization-block">
                <div className="digitalization-text">
                    <p>
                    At Envigrow Sustainable Solutions, we transform existing waste management systems 
                    from waste generation to scientific disposal, ensuring compliance with SWM Rules 
                    2016. Here’s how we do it:
                    </p>
                    <p>
                    Using advanced route optimization and digital technologies, we enhance efficiency 
                    in collection, transport, and processing.
                    </p>
                    <p>
                    Our goal is to foster a circular economy by transforming waste into valuable resources through innovative recycling and composting.
                    </p>
                </div>
                <img
                    src={Main}
                    className="digitalization-image"
                    alt="About us"
                />
            </div>
            <div className="ice-block">
                <section id="home" className="ice-image">
                    <Carousel>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={ice1}
                                alt="slide 1"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={ice2}
                                alt="slide 2"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={ice3}
                                alt="slide 3"
                            />
                        </Carousel.Item>
                    </Carousel>
                </section>
                <div className="ice-text">
                    <h1>IEC and Awareness <br/> Activities</h1>
                    <p>
                    At Envigrow, we prioritize waste management through awareness and education. 
                    Our IEC initiatives engage communities with live demonstrations on proper waste 
                    segregation—empowering them to maintain a cleaner environment.  
                    </p>
                    <p>
                    Our team conducts these activities in diverse neighborhoods, ensuring everyone has 
                    the tools for responsible waste management.
                    </p>
                </div>
            </div>
            <div className="ice-block">
                <div className="ice-text">
                    <h1>Door to Door <br/> collection</h1>
                    <p>
                    Envigrow's door-to-door waste collection system uses GPS monitoring and QR-based 
                    tracking to ensure regular servicing of all waste collection points. This improves 
                    segregation and reduces contamination, enhancing recycling efforts. 
                    </p>
                    <p>
                    Our vehicles handle various waste types, including dry, wet, and sanitary materials, ensuring 
                    proper management from collection. Leveraging technology, we provide a reliable and 
                    transparent service that benefits the community and the environment.
                    </p>
                </div>
                <section id="home" className="ice-image">
                    <Carousel>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={d2d1}
                                alt="slide 1"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={d2d2}
                                alt="slide 2"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={d2d3}
                                alt="slide 3"
                            />
                        </Carousel.Item>
                    </Carousel>
                </section>
            </div>
            <div className="ice-block">
                <section id="home" className="ice-image">
                    <Carousel>
                    <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={gis1}
                                alt="slide 1"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={gis1}
                                alt="slide 2"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={gis1}
                                alt="slide 3"
                            />
                        </Carousel.Item>
                    </Carousel>
                </section>
                <div className="ice-text">
                    <h1>GIS based route mapping & <br/> digitalization </h1>
                    <p>
                    Text needed
                    </p>
                    <p>
                    Text needed
                    </p>
                </div>
            </div>
            <div className="ice-block">
                <div className="ice-text">
                    <h1>Material Recovery <br/> Facility</h1>
                    <p>
                    Envigrow operates advanced Material Recovery Facilities (MRFs) where waste is sorted, 
                    baled, and prepared for recycling or disposal. Our trained staff efficiently separates 
                    recyclables like plastics, paper, and metals, reducing landfill waste and promoting 
                    recycling. 
                    </p>
                    <p>
                    The sorted materials are then baled and transported to recycling plants. 
                    Our MRFs are essential in our mission to create sustainable waste management solutions, 
                    turning waste into resources and supporting a circular economy.
                    </p>
                </div>
                <section id="home" className="ice-image">
                    <Carousel>
                    <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={mrf1}
                                alt="slide 1"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={mrf2}
                                alt="slide 2"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={mrf3}
                                alt="slide 3"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={mrf4}
                                alt="slide 3"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={mrf5}
                                alt="slide 3"
                            />
                        </Carousel.Item>
                    </Carousel>
                </section>
            </div>
            <div className="ice-block">
                <section id="home" className="ice-image">
                    <Carousel>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={pcf1}
                                alt="slide 1"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={pcf2}
                                alt="slide 2"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={pcf3}
                                alt="slide 3"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={pcf4}
                                alt="slide 3"
                            />
                        </Carousel.Item>
                    </Carousel>
                </section>
                <div className="ice-text">
                    <h1>Processing and Composting<br/> Facility</h1>
                    <p>
                    Text  needed 
                    </p>
                    <p>
                    Text needed
                    </p>
                </div>
            </div>
            <div className="ice-block">
                <div className="ice-text">
                    <h1>GPS enabled swachhata <br/> control room</h1>
                    <p>
                    Text needed 
                    </p>
                    <p>
                    Text needed
                    </p>
                </div>
                <section id="home" className="ice-image">
                    <Carousel>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={d2d1}
                                alt="slide 1"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={d2d2}
                                alt="slide 2"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={d2d3}
                                alt="slide 3"
                            />
                        </Carousel.Item>
                    </Carousel>
                </section>
            </div>
            <div className="ice-block">
                <section id="home" className="ice-image">
                    <Carousel>
                    <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={app1}
                                alt="slide 1"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={app1}
                                alt="slide 2"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={app1}
                                alt="slide 3"
                            />
                        </Carousel.Item>
                    </Carousel>
                </section>
                <div className="ice-text">
                    <h1>Linking Technology With <br/> Operations</h1>
                    <p>
                    Text needed 
                    </p>
                    <p>
                    Text needed
                    </p>
                </div>
            </div>
            
        </div>
    );
};

export default Howwedo;
