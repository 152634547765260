import './Hero.css';
// import wave from '../../assets/wave.svg'; // Import the wave.svg file
import image1 from '../../assets/hero/image11.jpeg';
import image2 from '../../assets/hero/image22.jpeg';
import image3 from '../../assets/hero/working-model.jpeg';
import image4 from '../../assets/hero/image4.jpg';
import image5 from '../../assets/hero/image5.jpeg';
import image6 from '../../assets/hero/image6.jpeg';
import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

const Hero = () => {
  return (
    <div className="hero-section">
      <div className='heading2-background'>
        <img src={image1} className="image" alt="EnviGrow" />
        <div className='heading1'>
          <p>Waste management cannot be sustainable until & unless we <br/>create value to each & every fraction of waste</p>
        </div>
        <img src={image2} className="image" alt="EnviGrow" />
        
        <div className='heading2'>
          <p>That's why we at <span className="envigrow-text">EnviGrow</span>, are determined to solve most crucial challenges of <br/> Waste management, Climate Change & Unemployment with our unique <br/> sustainable solid waste management.</p>
        </div>
        <Button variant="success" href="/career" className="ml-3 my-3" style={{ backgroundColor:'#7fd434'}}>
          JOIN US
        </Button>
      </div>
      
      {/* <div className="our-actions-section wave-background"> Add the new class here */}
        <div className='heading3'>OUR ACTIONS</div>
        <Container className='work-image'>
          <div className="row">
            <div className="work-image-container col-12 col-md-4">
              <img 
                src={image4} 
                className="image2" 
                alt="EnviGrow" 
              />
              <div className="text-overlay">Door to Door Collection of Segregated Dry, Wet and Sanitary Waste1</div> 
            </div>
            <div className="work-image-container col-12 col-md-4">
              <img 
                src={image5} 
                className="image2" 
                alt="EnviGrow" 
              />
              <div className="text-overlay">GIS based route mapping & digitalization of SWM system</div>
            </div>
            <div className="work-image-container col-12 col-md-4">
              <img 
                src={image6} 
                className="image2" 
                alt="EnviGrow" 
              />
              <div className="text-overlay">Design & operation of dry waste material recovery facility (MRF) </div> 
            </div>
          </div>
        </Container>
      {/* </div> */}
      
      <div className="image-container">
        <div className="text-container">
          <p className="working-model">"OUR<br/>WORKING MODEL"</p>
          <Button variant='success' href="ABOUT-US" className="know-more-btn ml-3" style={{ backgroundColor:'#0c90a4' }}>Know More</Button>
        </div>
        <img src={image3} className="image3" alt="Working-model" />
      </div>
    </div>
  );
};

export default Hero;
