import React, { useEffect, useRef } from "react";
import './About.css';
import image1 from '../../assets/about/vector6.jpg';
import image2 from '../../assets/about/vector5.png';
import image3 from '../../assets/about/vector8.png';
import about from '../../assets/about/about.png';

import galleryImage1 from '../../assets/about/beg1.jpeg';
import galleryImage2 from '../../assets/about/beg2.jpeg';
import galleryImage3 from '../../assets/about/beg3.jpeg';
import galleryImage4 from '../../assets/about/beg4.jpeg';
import galleryImage5 from '../../assets/about/beg5.jpeg';
import galleryImage6 from '../../assets/about/beg6.jpeg';
import galleryImage7 from '../../assets/about/beg7.jpeg';
import galleryImage8 from '../../assets/about/beg8.jpeg';

import Carousel from 'react-bootstrap/Carousel';

const About = () => {
    const upperImagesRef = useRef([]);
    const lowerImagesRef = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry, index) => {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        entry.target.classList.add('in-view');
                    }, index * 200); // Apply a delay based on the index
                }
            });
        }, { threshold: 0.1 });

        upperImagesRef.current.forEach(image => {
            if (image) observer.observe(image);
        });

        lowerImagesRef.current.forEach(image => {
            if (image) observer.observe(image);
        });

        return () => {
            upperImagesRef.current.forEach(image => {
                if (image) observer.unobserve(image);
            });

            lowerImagesRef.current.forEach(image => {
                if (image) observer.unobserve(image);
            });
        };
    }, []);

    return (
        <div className="About-section">
            <div className="about-image">
                <img
                    src={image1}
                    className="vector1"
                    alt="About us"
                />
                <div className="about-text" style={{ backgroundColor: '#0c90a469' }}>
                    <p>
                        EnviGrow Sustainable Solutions, founded in 2021 by a team of environmental
                        engineers from IIT Bombay, is dedicated to addressing critical socio-environmental
                        challenges. Our mission is to tackle waste management, climate change, and
                        unemployment through sustainable solid waste management practices.
                    </p>
                    <p>
                        We aim to implement effective waste management solutions in semi-urban and
                        rural areas, transforming these regions into cleaner, more sustainable environments.
                        Our work not only improves local conditions but also promotes a healthier and more
                        sustainable lifestyle for residents.
                    </p>
                    <p>
                        Collaborating closely with government bodies, EnviGrow is committed to
                        achieving the Sustainable Development Goals (SDGs) and fostering a cleaner,
                        greener future for all.
                    </p>
                </div>
            </div>
            <div className="beg-block">
                <div className="upper-beg-images">
                    <img
                        src={galleryImage1}
                        className="beg-image"
                        alt="About us"
                        ref={(el) => upperImagesRef.current[0] = el}
                    />
                    <img
                        src={galleryImage2}
                        className="beg-image"
                        alt="About us"
                        ref={(el) => upperImagesRef.current[1] = el}
                    />
                    <img
                        src={galleryImage3}
                        className="beg-image"
                        alt="About us"
                        ref={(el) => upperImagesRef.current[2] = el}
                    />
                    <img
                        src={galleryImage4}
                        className="beg-image"
                        alt="About us"
                        ref={(el) => upperImagesRef.current[3] = el}
                    />
                </div>
                <div className="beg-text-block">
                    <section id="home" className="beg-text-image">
                        <Carousel>
                            <Carousel.Item>
                                <p>
                                In October 2014, the Swachh Bharat Mission was launched, significantly raising awareness and cleanliness 
                                standards in cities. However, despite its successes, the mission's focus has remained primarily urban, 
                                attracting more private players and leaving a critical gap in scientific solid waste management in 
                                semi-urban and rural areas where more than 60% of Indians reside.
                                <br/>Recognizing this pressing need, EnviGrow Sustainable Solutions stepped in to fill this void. Our mission 
                                is to make our country garbage-free by providing sustainable solid waste management solutions to underserved 
                                areas.
                                </p>
                            </Carousel.Item>
                            <Carousel.Item>
                            <p>
                                In 2021, we began our operations with just one Urban Local Body (ULB) and two dedicated team 
                                members. Our initial challenge was to manage only 700 kg of waste daily. We worked tirelessly, 
                                sorting through heaps of waste, engaging with the local community, and ensuring proper disposal 
                                and recycling practices.<br/>
                                Our approach has always been community-centric. We engaged with local families, educating them on the 
                                importance of waste segregation and recycling. Through workshops and door-to-door campaigns, we have 
                                fostered a culture of responsibility and environmental consciousness.<br/>
                                Today, we are proud to operate in 8 ULBs, having created over 100 dignified employments and 
                                managing more than 100+ tonnes of waste daily. Our growth is a testament to the hard work and 
                                dedication of our team and the trust and support of the communities we serve.
                            </p>
                            </Carousel.Item>
                        </Carousel>
                    </section>
                </div>
                <div className="lower-beg-images">
                    <img
                        src={galleryImage5}
                        className="beg-image"
                        alt="About us"
                        ref={(el) => lowerImagesRef.current[0] = el}
                    />
                    <img
                        src={galleryImage6}
                        className="beg-image"
                        alt="About us"
                        ref={(el) => lowerImagesRef.current[1] = el}
                    />
                    <img
                        src={galleryImage7}
                        className="beg-image"
                        alt="About us"
                        ref={(el) => lowerImagesRef.current[2] = el}
                    />
                    <img
                        src={galleryImage8}
                        className="beg-image"
                        alt="About us"
                        ref={(el) => lowerImagesRef.current[3] = el}
                    />
                </div>
            </div>
            <div className="vision-image">
                <img
                    src={image2}
                    className="vector2"
                    alt="vision"
                />
                <div className="vision-text">
                    <h1 className="vision-heading">Vision</h1>
                    <p>
                        EnviGrow Sustainable Solutions LLP envisions a future where waste is eliminated,
                        and resources are utilized efficiently in a circular economy paradigm. We strive
                        towards a world where sustainability is ingrained in every aspect of society,
                        leading to a zero-waste environment.
                    </p>
                </div>
            </div>
            <div className="mission-image">
                <div className="mission-text">
                    <h1 className="mission-heading">Mission</h1>
                    <p>
                        EnviGrow Sustainable Solutions LLP is committed to addressing solid waste management
                        challenges in India's semi-urban and rural regions through collaborative efforts with
                        local governments. By fostering partnerships, we create dignified employment within a
                        circular economy model, aiming to eliminate both waste and unemployment. Our mission is
                        to empower communities, drive environmental sustainability, and align with the overarching
                        goals of the Swachh Bharat Mission nationwide.
                    </p>
                </div>
                <img
                    src={image3}
                    className="vector3"
                    alt="mission"
                />
            </div>
            <div className="about-image">
                <img
                    src={about}
                    className="vector1"
                    alt="About us"
                />
                <div className="about-text">
                    <p>
                        Solid waste management has emerged as a pressing concern in contemporary times,
                        posing significant challenges in collection, transportation, segregation, and
                        scientific disposal. The issue is exacerbated by the availability of disposal
                        sites and the uncontrolled dumping of waste in disorganized and unhygienic
                        dumping sites at the outskirts of towns/cities.
                    </p>
                    <p>
                        EnviGrow possesses the expertise and capabilities to efficiently execute solid
                        waste management projects on turn key basis. Our comprehensive solutions adhere
                        to the MSW Rules 2016, encompassing municipal solid waste collection, transportation,
                        segregation, and safe disposal. Currently, EnviGrow manages over 100 MT of municipal
                        solid waste daily using industry-recognized processes, ensuring optimal performance
                        in materials recovery and energy utilization.
                    </p>
                </div>
            </div>
            <div className="our-work-section wave-background-work">
            </div>
        </div>
    );
};

export default About;
