import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './Photos.css';

import image1 from '../../assets/Media/image1.jpeg';
import image2 from '../../assets/Media/image2.jpeg';
import image3 from '../../assets/Media/image3.jpeg';
import image4 from '../../assets/Media/image4.jpeg';
import image5 from '../../assets/Media/image5.jpeg';
import image6 from '../../assets/Media/image6.jpeg';
import image7 from '../../assets/Media/image7.jpeg';
import image8 from '../../assets/Media/image8.jpeg';
import image9 from '../../assets/Media/image9.jpeg';
import image10 from '../../assets/Media/image10.jpeg';
import image11 from '../../assets/Media/image11.jpeg';
import image12 from '../../assets/Media/image12.jpeg';
import image13 from '../../assets/Media/image13.jpeg';
import image14 from '../../assets/Media/image14.jpeg';
import image15 from '../../assets/Media/image15.jpeg';
import image16 from '../../assets/Media/image16.jpeg';
import image17 from '../../assets/Media/image17.jpeg';
import image18 from '../../assets/Media/image18.jpeg';
import image19 from '../../assets/Media/image19.jpeg';
import image20 from '../../assets/Media/image20.jpeg';
import image21 from '../../assets/Media/image21.jpeg';

const Photos = () => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [loadedImages, setLoadedImages] = useState([]);
  const [loading, setLoading] = useState(true);

  const images = [
    image1, image2, image3, image4, image5, image6, image7, image8,
    image9, image10, image11, image12, image13, image14, image15,
    image16, image17, image18, image19, image20, image21
  ];

  useEffect(() => {
    const preloadImages = () => {
      let loaded = [];
      let loadCount = 0;
      images.forEach((src, index) => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
          loaded[index] = src;
          loadCount++;
          if (loadCount === images.length) {
            setLoadedImages(loaded);
            setLoading(false);
          }
        };
      });
    };

    preloadImages();
  }, [images]);

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" />
      </Container>
    );
  }

  return (
    <Container>
      <Row className="justify-content-center mt-4">
        {loadedImages.map((image, index) => (
          <Col xs={12} sm={6} md={4} key={index} className="mb-4">
            <div className="image-box" onClick={() => { setPhotoIndex(index); setIsOpen(true); }}>
              <img src={image} alt={`Gallery ${index}`} className="img-fluid" />
            </div>
          </Col>
        ))}
      </Row>

      {isOpen && (
        <Lightbox
          mainSrc={loadedImages[photoIndex]}
          nextSrc={loadedImages[(photoIndex + 1) % loadedImages.length]}
          prevSrc={loadedImages[(photoIndex + loadedImages.length - 1) % loadedImages.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + loadedImages.length - 1) % loadedImages.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % loadedImages.length)}
        />
      )}
    </Container>
  );
};

export default Photos;
