import React from "react";
import './Terms.css';

const Terms = () => {
    return (
        <div className="Term-section">
            <div className="terms">
                Website Policies
            </div>
            <div className="content">
                <h2>Terms of Use</h2>
                <p>ENVIGROW SUSTAINABLE SOLUTIONS LLP is managing the content of the website. Though all 
                    efforts have been made to ensure the accuracy and currency of the content on this 
                    website, the same should not be construed as a statement of law or used for any 
                    legal purposes.
                    In no event will the Envigrow be liable for any expense, loss or damage including, 
                    without limitation, indirect or consequential loss or damage, or any expense, loss 
                    or damage whatsoever arising from use, or loss of use, of data, arising out of or 
                    in connection with the use of this Portal.  These terms and conditions shall be 
                    governed by and construed in accordance with the Indian Laws. Any dispute arising 
                    under these terms and conditions shall be subject to the exclusive jurisdiction of 
                    the courts of India.</p>
                
                <h2>Copyright Policy</h2>
                <p>Material featured on this website may be reproduced free of charge after taking proper 
                    permission by sending a mail to us. However, the material has to be reproduced accurately 
                    and not to be used in a derogatory manner or in a misleading context. Wherever the material 
                    is being published or issued to others, the source must be prominently acknowledged. 
                    However, the permission to reproduce this material shall not extend to any material 
                    which is identified as being copyright of a third party. Authorization to reproduce 
                    such material must be obtained from the departments/copyright holders concerned</p>
                
                <h2>Privacy Policy</h2>
                <p>This website does not automatically capture any specific personal information from you, 
                    (like name, phone number or e-mail address), that allows us to identify you individually. 
                    If the website requests you to provide personal information, you will be informed for 
                    the particular purposes for which the information is gathered e.g. feedback form and 
                    adequate security measures will be taken to protect your personal information. We do 
                    not sell or share any personally identifiable information volunteered on the website 
                    site to any third party (public/private). Any information provided to this website will 
                    be protected from loss, misuse, unauthorized access or disclosure, alteration, or 
                    destruction. We gather certain information about the User, such as Internet protocol 
                    (IP) addresses, domain name, browser type, operating system, the date and time of the 
                    visit and the pages visited. We make no attempt to link these addresses with the 
                    identity of individuals visiting our site unless an attempt to damage the site has 
                    been detected.</p>
            </div>
        </div>
    );
};

export default Terms;
