import React, { useEffect, useRef } from 'react';
import './ImpactSection.css';
import Container from 'react-bootstrap/Container';
import sustainable from '../../assets/hero/sustainable.png';

const ImpactSection = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const animateValue = (id, start, end, duration, appendTon) => {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        const value = Math.floor(progress * (end - start) + start);
        document.getElementById(id).innerText = appendTon ? value + "+ ton" : value + "+";
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    };

    const handleIntersection = (entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        animateValue("jobsCreated", 0, 100, 2000, false);
        animateValue("plasticRecovered", 0, 28870, 2000, true); // Append " ton" for this animation
        animateValue("womenSupported", 0, 2600, 2000, true); // Append " ton" for this animation
        animateValue("villagesOperational", 0, 8, 2000, false);
        observer.disconnect(); // Disconnect observer after animation starts
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
    });

    observer.observe(sectionRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="impact-section" ref={sectionRef}>
      {/* <div className="our-work-section wave-background-work"> */}
        <h2>Our Impacts So Far</h2>
        <div className="impact-item">
          <span id="jobsCreated">0</span>
          <p>Dignified Employment Created</p>
        </div>
      {/* </div> */}
      <div className='impact-item-back'>
        <div className="impact-item">
          <span id="plasticRecovered">0</span>
          <p>Total Amount of Waste Diverted from Landfill</p>
        </div>
        <div className="impact-item">
          <span id="womenSupported">0</span>
          <p>Total Amount of Waste Recycled</p>
        </div>
        <div className="impact-item">
          <span id="villagesOperational">0</span>
          <p>Operational Towns</p>
        </div>
      </div>
      <img src={sustainable} className="sustainableimage" alt="Envigrow" />
      {/* <div className='container-back'>
        <Container>
          <div className="row">
            <div className="col-12 col-md-3">
              <div className="info-container">
                <div className="container-text">
                  TREE PLANTATION
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="info-container">
                <div className="container-text">
                  TREE PLANTATION
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="info-container">
                <div className="container-text">
                  TREE PLANTATION
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="info-container">
                <div className="container-text">
                  TREE PLANTATION
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div> */}
      
    </div>
  );
};

export default ImpactSection;
