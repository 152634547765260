import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import NavigationBar from './components/Navbar/Navabr';
import ImpactSection from './components/ImpactSection/ImpactSection';
import Hero from './components/Hero/Hero';
import Footer from './components/Footer/Footer';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Howwedo from './components/Howwedo/Howwedo';
import Photos from './components/Photos/Photos';
import Terms from './components/Terms/Terms';

function App() {
  return (
    <Router>
      <div className="App">
        <header id="App-header">
          <NavigationBar />
        </header>

        <Routes>
          <Route path="/" element={
            <>
              <Hero />
              <ImpactSection />
            </>
          } />
          <Route path="/about-us" element={<About />} />
          {/* <Route path="/Who-we-serve" element={<Photos />} /> */}
          <Route path="/HOW-WE-DO" element={<Howwedo/>} />
          <Route path="/media" element={<Photos />} />
          <Route path="/career" element={<Contact />} />
          <Route path="/Terms" element={<Terms />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;